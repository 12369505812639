import React, { Component, Fragment } from 'react';
import { withRouter } from 'next/router';
import connect from "./layout.connect";
import Navbar from "./navbar/navbar2.component";
import Footer from "./footer/footer2.component";
import ErrorBoundary from './widgets/errorboundary/errorboundary.component';
import Spinner from '../util-components/spinner/spinner';
import Preloader from './widgets/preloader/preloader';
import { Config } from '../../base/portal_env_config/config'

class Layout extends Component<{}, any> {
    props: any;
    pwaInstall: any;

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        let self = this;
        if ('serviceWorker' in navigator) {
            // navigator.serviceWorker.getRegistrations()
            //     .then(function (registrations) {
            //         for (let registration of registrations) {
            //             console.log('Service worker registration is ', registration);
            //         }
            //     });
            // window.addEventListener('load', function () {
            //     navigator.serviceWorker.register('service-worker.js', { scope: '/' }).then(function (registration) {
            //         console.log('SW registered: ', registration)
            //     }).catch(function (registrationError) {
            //         console.log('SW registration failed: ', registrationError)
            //     })
            // })
        }

        // {
        //     Config.TEST_TYPE >= Config.LIVE && window &&
        //         // Capture event and defer
        //         window.addEventListener('beforeinstallprompt', (e: any) => {
        //             let lastPrompt = window.localStorage.getItem('pwaprompt') ? +window.localStorage.getItem('pwaprompt') : 0;
        //             let showPrompt = false;
        //             if (+lastPrompt < (new Date().getTime() - (24 * 60 * 60 * 1000))) {
        //                 showPrompt = true;
        //             }

        //             if (showPrompt) {
        //                 window.localStorage.setItem('pwaprompt', '' + new Date().getTime());
        //                 e.preventDefault();
        //                 self.pwaInstall = e;
        //                 self.listenToUserAction();

        //                 if ((/(Win|Mac|Linux)/i.test(navigator.userAgent)) && (/(Win|Mac|Linux)/i.test(navigator.platform))) {
        //                     e.prompt && e.prompt()
        //                 }
        //             }
        //         });

        //     window.addEventListener('appinstalled', (evt) => {
        //         console.log('App installed');
        //     });
        // }

        this.checkWebpSupport().then(hasWebP => {
            window.localStorage.setItem("webpSupport", "" + hasWebP);
        });
    }

    // listen to install button clic
    listenToUserAction() {
        const installBtn = document.querySelector(".install-btn");
        if (installBtn) {
            installBtn.addEventListener("click", this.presentAddToHome);
        }
    }

    // present install prompt to user
    presentAddToHome() {
        {
            Config.TEST_TYPE >= Config.LIVE &&
                this.pwaInstall.prompt();  // Wait for the user to respond to the prompt
            this.pwaInstall.userChoice
                .then(choice => {
                    if (choice.outcome === 'accepted') {
                        console.log('User accepted the install prompt');
                    } else {
                        console.log('User dismissed the install prompt');
                    }
                });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.auth?.userDetails?.profileId != prevProps.auth?.userDetails?.profileId && !this.props.auth?.userDetails?.profileId) {
            this.props.setSelectedServiceList(this.props?.diagnostic?.isHomeCollection, new Map<number, any>());
        }
    }

    checkWebpSupport() {
        return new Promise(res => {
            const webP = new Image();
            webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
            webP.onload = webP.onerror = () => {
                res(webP.height === 2);
            };
        })
    }

    isRouteActive(route: string): boolean {
        return (this.props.router.pathname + "").includes(route);
    }

    isExactRouteActive(route: string): boolean {
        return this.props.router.pathname == route;
    }

    render() {
        return (
            <React.Fragment>
                {this.props?.hide ?
                    <ErrorBoundary>
                        <div className="content layout-content">
                            {/* <div className={(this.isExactRouteActive('/video')) ? "container-fluid" : "container"}>{this.props.children}</div> */}
                            {this.props.children}
                        </div>
                    </ErrorBoundary> :
                    <Fragment>
                        <Preloader />
                        <ErrorBoundary>
                            <Navbar disableHeaderNavigations={this.props?.disableHeaderNavigations} disableLogoNavigation={this.props?.disableLogoNavigation} ></Navbar>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <div className="content layout-content">
                                {/* <div className={(this.isExactRouteActive('/video')) ? "container-fluid" : "container"}>{this.props.children}</div> */}
                                {this.props.children}
                            </div>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <Footer></Footer>
                        </ErrorBoundary>
                    </Fragment>}
                {this.props?.layout?.isLoading === true ? <Spinner spin={this.props.layout.isLoading}></Spinner> : ''}
                <div className='call-widget call-widget1'>
                    <button className='d-inline-block d-lg-none mr-2 call-btn'><a href='tel:040 21000000' className='txt-primary txt-medium'><i className='fa fa-phone' aria-hidden="true" /></a></button>
                </div>
                <div className='call-widget'>
                    <button className='whatsapp-btn'><a href='https://api.whatsapp.com/send/?phone=919121999111&text&type=phone_number&app_absent=0' className='text-white'><img src={Config.portal.iconspath + "images/ic-whatsapp.png"} alt='whatsapp icon' width='32' height='32' /></a></button>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(Layout));