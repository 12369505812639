import React, { Component } from 'react';
import './spinner.scss'

export default class Spinner extends Component {
    state: any = {};
    props: any;

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.spin===true)
            return (
                <React.Fragment>
                    <div className="loading-spinner">
                        <div className="loading-spinner-body">
                            <svg className="loader" viewBox="0 0 150 150">
                                <g className="points">
                                    <circle className="ciw" cx="50" cy="50" r="50" fill="#fff" />
                                    <circle className="ci2" cx="5" cy="50" r="4" />
                                    <circle className="ci1" cx="95" cy="50" r="4" />
                                </g>
                            </svg>
                        </div>
                    </div>
                </React.Fragment>
            )
        else return <React.Fragment></React.Fragment>
    }
}
